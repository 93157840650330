<template>
  <header class="header">
    <div class="logo">
      <router-link to="/">
        <img
          class="logo__img"
          src="../../assets/nepal-gov.svg"
          alt="Nepal Government Logo"
        />
        <span class="logo__text">
          मेल्लेख गाउँपालिकाकाे
          <br />एकीकृत सूचना प्रवाह प्रणाली
        </span>
      </router-link>
    </div>
    <h1>{{ $store.state.heading }}</h1>
    <div class="header__select"  >
      <multiselect
        v-show="showFilter"
        v-model="value"
        :options="fiscalYear"
        :searchable="false"
        :selectedLabel="''"
        :deselectLabel="''"
        :selectLabel="''"
        placeholder="आ.व. छनौट गर्नुहोस्"
      ></multiselect>
    </div>
  </header>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect
  },
  data: function () {
    return {
      value: "आ. व. २०७७-७८",
      fiscalYearList: [
        { value: "2077-78", label: "आ. व. २०७७-७८" },
        { value: "2078-79", label: "आ. व. २०७८-७९" },
        { value: "2079-80", label: "आ. व. २०७९-८०" }
      ]
    };
  },
  computed: {
    fiscalYear() {
      return this.fiscalYearList.map((ele) => {
        return ele.label;
      });
    },
    showFilter() {
      return this.$route.meta.showFilter;
    }
  },
  watch: {
    value: {
      handler(val) {
        let selected = this.fiscalYearList.find((ele) => ele.label === val);
        if (selected) {
          this.$store.dispatch("setFiscalYear", selected.value);
        }
      },
      immediate: true
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.header {
  align-items: center;
  background-color: #05050f;
  display: flex;
  /* height: 300px; */
  justify-content: space-between;
  margin-right: 800px;
  padding: 75px 100px;
  position: fixed;
  top: 0;
  width: calc(100vw - 800px);
  z-index: 2;

  @media screen and (max-width: 3839px) {
    margin-right: 670px;
    padding: 70px;
    width: calc(100vw - 720px);
  }

  @media screen and (max-width: 3199px) {
    margin-right: 320px;
    padding: 30px 36px;
    width: calc(100vw - 320px);
  }

  @media screen and (max-width: 1800px) {
    margin-right: 285px;
    width: calc(100vw - 285px);
  }

  &__select {
    width: 500px;
    @media screen and (max-width: 3199px) {
      width: 212px;
    }
  }

  h1 {
    @media screen and (max-width: 3199px) {
      font-size: 28px;
      line-height: 42px;
    }
  }
}

.logo {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    margin-right: 28px;

    @media screen and (max-width: 3199px) {
      width: 57px;
      height: 48px;
      margin-right: 8px;
    }
  }

  &__text {
    font-size: 32px;
    line-height: 48px;
    color: $neutrals-ash;

    @media screen and (max-width: 3199px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.multiselect {
  width: 500px;

  @media screen and (max-width: 3199px) {
    width: 212px;
  }

  &__select {
      border: solid $neutrals-white;
      border-width: 0 5px 5px 0;
      display: inline-block;
      height: 24px;
      padding: 3px;
      right: 60px;
      top: 45%;
      transform: translateY(-50%) rotate(45deg);
      width: 24px;
      @media screen and (max-width: 3199px) {
        border-width: 0 2px 2px 0;
        height: 10px;
        right: 27px;
        width: 10px;;
      }

      @media screen and (max-width: 1800px) {
        height: 8px;
        width: 8px;
      }
  }
  &--active  {
    .multiselect__select {
      transform: rotate(225deg);
    }
  }

  &__content-wrapper {
    background: none;
    border: none;
    box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.9);
    border-radius: 20px;
    margin-top: 16px;
    max-height: 340px !important;

    @media screen and (max-width: 3199px) {
      border-radius: 7px;
      box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.9);
      margin-top: 8px;
    }
  }

  &__placeholder {
    margin-bottom: 6px;
    padding-top: 0;
  }
}

.multiselect__tags {
  font-size: 40px;
  padding: 24px 48px 24px 60px;
  cursor: pointer;
  background-color: #05050f;
  border-width: 0;
  border-radius: 20px;
  background-clip: padding-box;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 20px;
    background: white;
    z-index: -1;

    @media screen and (max-width: 3199px) {
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border-radius: 7px;
    }
  }

  @media screen and (max-width: 3839px) {
    padding: 14px 40px 14px 75px;
  }

  @media screen and (max-width: 3199px) {
    font-size: 14px;
    border-radius: 7px;
    padding: 9px 10px 5px 27px;
  }
}

.multiselect--active {
  .multiselect__tags:after {
    background: linear-gradient(to right, #E323FF, #7517F8);
  }
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 40px;

  @media screen and (max-width: 3839px) {
    font-size: 36px;
  }

  @media screen and (max-width: 3199px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1800px) {
    font-size: 14px;
  }
}

.multiselect__option {
  line-height: 60px;
  padding: 28px 80px;

  @media screen and (max-width: 3199px) {
    line-height: 21px;
    padding: 12px 27px;
  }
}

.multiselect__input,
.multiselect__single {
  background: none;
  border-radius: 0;
  line-height: 60px;
  color: white;
  padding-left: 0;

  @media screen and (max-width: 3199px) {
    line-height: 21px;
  }
}

.multiselect__select:before {
  content: none;
}

.multiselect__element {
  background-color: $neutrals-midnight;
  color: $neutrals-white;
}

.multiselect__option--highlight {
  background: $secondary;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: $secondary;
}

.multiselect__option--selected {
  background: $neutrals-nightsky;
  color: $neutrals-white;
}

.multiselect--active:not(.multiselect--above) .multiselect__current, .multiselect--active:not(.multiselect--above) .multiselect__input, .multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  @media screen and (max-width: 3199px) {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
}
</style>
